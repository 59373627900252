import { Tooltip, Form, Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import auth from 'services/auth'
import { ProductService } from 'services'
import business from 'services/business'
import { get } from 'lodash'

const { Option } = Select
const ProviderField = (props) => {
  const preview = document.URL.includes('preview')
  const [options, setOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [first, setFirst] = useState(true)
  const [mount, setMount] = useState(true)
  const [value, setValue] = useState({ product: '', provider: '' })

  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const queryString = window.location.search
  const [entity_detail, setEntityDetail] = useState({})
  const { options_type } = props

  const params = new URLSearchParams(queryString)
  const entityType = params.get('entity_type')
    ? params.get('entity_type')
    : preview && previewUser?.details
      ? previewUser?.details?.user_type
      : user?.details?.user_type
  const entity = params.get('entity')
    ? params.get('entity')
    : preview && previewUser?.details
      ? previewUser?.details?.id
      : user?.details?.id

  useEffect(() => {
    if (entityType?.toLowerCase() === 'business') {
      getProviders(entity)
    } else {
      getEntity()
    }
  }, [])

  useEffect(() => {
    if (!first && props.dependantValue && props.dependsOn) {
      setValue(props.filtering.values[props.dependantValue].defaultValue)
      props.onChange(props.filtering.values[props.dependantValue].defaultValue)
    }
  }, [props.dependantValue])

  useEffect(() => {
    if (mount && props.value) {
      setValue(props.value)
      if (props.value?.provider) {
        // getProducts(props.value.provider, entity_detail?.business)
        getEntity('product', props.value.provider)
      }
      setMount(false)
    }
  }, [props.value])

  const getEntity = (call_for = 'provider', provider_value) => {
    auth.detailEntity(entity).then((res) => {
      setEntityDetail(get(res, 'data.data', {}))
      if (call_for === 'provider') {
        getProviders(res?.data?.data?.business)
      } else {
        getProducts(provider_value, res?.data?.data?.business)
      }
    })
  }

  const getProviders = (business) => {
    let params_filter = {}
    if (options_type === 'general_insurance_provider') {
      params_filter['is_general_insurance_provider'] = true
    } else if (options_type === 'health_insurance_provider') {
      params_filter['is_health_insurance_provider'] = true
    } else if (options_type === 'life_insurance_provider') {
      params_filter['is_life_insurance_provider'] = true
      // } else if (options_type === 'asset_provider') {
      //   params_filter['is_asset_provider'] = true
      // } else if (options_type === 'liability_provider') {
      //   params_filter['is_liability_provider'] = true
    } else {
      params_filter['is_account_provider'] = true
    }
    ProductService.getProvider({ business, ...params_filter }).then((res) => {
      const providers = res?.data?.data || []
      setOptions(providers.map((item) => ({ value: item.id, name: item.subject })))
    })
  }

  const getProducts = (provider, business) => {
    ProductService.getProduct({ provider, business }).then((res) => {
      const providers = res?.data?.data || []
      setProductOptions(providers.map((item) => ({ value: item.id, name: item.subject })))
    })
  }

  const INSURUNCE_PROVIDER_TYPES = [
    'general_insurance_provider',
    'life_insurance_provider',
    'health_insurance_provider',
  ]

  return (
    <Form.Item>
      {props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label && (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          )}
          <div style={{ display: 'flex', flex: 1 }}>
            <Select
              style={{ width: `${props.width}%` }}
              placeholder="Please select"
              disabled={props.isReadOnly}
              value={value.provider}
              onChange={(val, option) => {
                props.onChange({
                  ...value,
                  provider: val,
                  providerName: option.children[0],
                  ...(options_type === 'asset_provider' ? { is_asset_account: true } : {}),
                  ...(options_type === 'liability_provider' ? { is_liability_account: true } : {}),
                })
                setValue((prev) => ({
                  ...prev,
                  provider: val,
                  providerName: option.children[0],
                  ...(options_type === 'asset_provider' ? { is_asset_account: true } : {}),
                  ...(options_type === 'liability_provider' ? { is_liability_account: true } : {}),
                }))
                getProducts(val, entity_detail?.business)
              }}
            >
              {options?.map?.((i, index) => {
                return (
                  <Option key={index} value={i.value}>
                    {i.name} {props.showValues ? i.value : null}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div style={{ display: 'flex', flex: 1 }}>
            <Select
              style={{ width: `${props.width}%` }}
              placeholder="Please select"
              disabled={props.isReadOnly}
              value={value.provider}
              onChange={(val, option) => {
                props.onChange({
                  ...value,
                  provider: val,
                  providerName: option.children[0],
                  ...(options_type === 'asset_provider' ? { is_asset_account: true } : {}),
                  ...(options_type === 'liability_provider' ? { is_liability_account: true } : {}),
                })
                setValue((prev) => ({
                  ...prev,
                  provider: val,
                  providerName: option.children[0],
                  ...(options_type === 'asset_provider' ? { is_asset_account: true } : {}),
                  ...(options_type === 'liability_provider' ? { is_liability_account: true } : {}),
                }))
                getProducts(val, entity_detail?.business)
              }}
            >
              {options?.map?.((i, index) => {
                return (
                  <Option key={index} value={i.value}>
                    {i.name} {props.showValues ? i.value : null}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      )}
      {props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label && (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {INSURUNCE_PROVIDER_TYPES.includes(options_type) ? ' Policy' : 'Account'}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          )}
          <div style={{ display: 'flex', flex: 1 }}>
            <Select
              style={{ width: `${props.width}%` }}
              placeholder="Please select"
              disabled={props.isReadOnly}
              value={value.product}
              onChange={(val, option) => {
                props.onChange({ ...value, product: val, productName: option.children[0] })
                setValue((prev) => ({ ...prev, product: val, productName: option.children[0] }))
              }}
            >
              {productOptions?.map?.((i, index) => {
                return (
                  <Option key={index} value={i.value}>
                    {i.name} {props.showValues ? i.value : null}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ display: 'block' }}>
              {INSURUNCE_PROVIDER_TYPES.includes(options_type) ? ' Policy' : 'Account'}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div style={{ display: 'flex', flex: 1 }}>
            <Select
              style={{ width: `${props.width}%` }}
              placeholder="Please select"
              disabled={props.isReadOnly}
              value={value.product}
              onChange={(val, option) => {
                props.onChange({ ...value, product: val, productName: option.children[0] })
                setValue((prev) => ({ ...prev, product: val, productName: option.children[0] }))
              }}
            >
              {productOptions?.map?.((i, index) => {
                return (
                  <Option key={index} value={i.value}>
                    {i.name} {props.showValues ? i.value : null}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default ProviderField

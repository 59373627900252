const { default: api } = require('./api')

const endpoints = {
  advise_for_options: 'data-saving/advise-for-options/',
  update_insurance_visibility: 'data-saving/form-data-show-hide/',
}

export const getAdviseForOptions = async (clientId = '') => {
  return api.get(endpoints.advise_for_options + clientId || '')
}

export const updateVisibilityOfInsuranceRecord = async (data) => {
  return api.put(endpoints.update_insurance_visibility, data)
}

export default { getAdviseForOptions, updateVisibilityOfInsuranceRecord }

import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Box from './box'
import './strategy.scss'
import {Button, Form, Modal, Select} from 'antd'
import { StrategyService, AuthService } from 'services'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
const { Option } = Select

const StrategySelection = ({ preview }) => {
  const [leftItems, setLeftItems] = useState([])
  const [centerItems, setCenterItems] = useState([])
  const [rightItems, setRightItems] = useState([])
  const [data, setData] = useState({})
  const [entity_data, setEntityData] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [user, setUser] = useState(false) // set user to set user in user list
  const [user_list, setUserList] = useState([]) // set user to set user in user list

  let navigate = useNavigate()

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let entity = null
  if (entity_type == 'client') {
    entity = params.get('entity')
  }


  const get_user_data = async (params = {}) => {
    const res = await AuthService.users({ user_type: 'user', ...params })
    setUserList(get(res, 'data.data'))
  }

  const getData = async () => {
    const entity_data = await getEntityData()
    const saved_data = await getStrategyData()
    const save_for_later = get(saved_data, 'save_for_later', [])
    const interested = get(saved_data, 'interested', [])
    setLeftItems(save_for_later)
    setRightItems(interested)
    const res = await StrategyService.getAdviceStrategy({
      business: entity_data.business,
      entity: entity,
    })
    const new_data = get(res, 'data.data', [])
    const resultObject = {}
    const idList = []

    new_data.forEach((item) => {
      resultObject[item.id] = item
      // Check if the id is not in save_for_later or interested arrays
      if (!save_for_later.includes(item.id) && !interested.includes(item.id)) {
        // Add the item to the result object with the id as the key (converted to string)

        // Push the id to the id list
        idList.push(item.id)
      }
    })
    setData(resultObject)
    setCenterItems(idList)
  }

  const getEntityData = async () => {
    const res = await AuthService.detailEntity(entity)
    const new_data = get(res, 'data.data', {})
    setEntityData(new_data)
    return new_data
  }

  const getStrategyData = async () => {
    const res = await StrategyService.getAdviceStrategySaved({
      entity: entity,
    })
    const new_data = get(res, 'data.data', {})
    return new_data
  }

  const SaveStrategyData = async (data) => {
    const res = await StrategyService.saveAdviceStrategySaved(data)
    // const new_data = get(res, "data.data", [])
    // console.log("new_data", new_data)
  }

  const resetStrategyData = async () => {
    const data = { entity: entity }
    const result = window.confirm(`Are you sure to delete reset ?`)
    if (result) {
      const res = await StrategyService.resetAdviceStrategySaved(data)
      getData()
    }
    // const new_data = get(res, "data.data", [])
    // console.log("new_data", new_data)
  }

  useEffect(() => {
    getData()
    get_user_data()
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const moveItem = (item, from, to) => {
    const removeFrom = from === 'left' ? leftItems : from === 'center' ? centerItems : rightItems
    const addTo = to === 'left' ? leftItems : to === 'center' ? centerItems : rightItems
    const updatedRemoveFrom = removeFrom.filter((i) => i !== item)
    const updatedAddTo = [...addTo, item]

    if (from === 'left') setLeftItems(updatedRemoveFrom)
    if (from === 'center') setCenterItems(updatedRemoveFrom)
    if (from === 'right') setRightItems(updatedRemoveFrom)

    if (to === 'left') setLeftItems(updatedAddTo)
    if (to === 'right') setRightItems(updatedAddTo)

    let action = 'save_for_later'
    if (to === 'left') {
      action = 'save_for_later'
    } else if (to === 'right') {
      action = 'interested'
    }
    const data = { entity: entity, strategy_id: item, action: action }
    SaveStrategyData(data)
  }

  const createStrategyHistory = async () => {
    const data = { entity: entity, business: entity_data.business , business_user: user}
    const result = window.confirm(`Are you sure to create history ?`)
    if (result) {
      const res = await StrategyService.createAdviceStrategyHistory(data)
      const history_id = get(res, 'data.data.id', [])
      navigate(
        `/preview/user/advice-strategy-scenario?entity=${entity}&entity_type=client&history=${history_id}&business_user=${user}`
      )
    }
  }

  const Main = (
    <div style={{ backgroundColor: '#fff' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="medium"
          type="primary"
          style={{ marginRight: '10px' }}
          onClick={resetStrategyData}
        >
          Reset
        </Button>
        <Button size="medium" type="primary" onClick={showModal}>
          Create Scenario
        </Button>
      </div>
      <div>
        <DndProvider backend={HTML5Backend}>
          <div className="stratergy_container">
            <Box
              title="Saved for later"
              items={leftItems}
              setItems={setLeftItems}
              canDropItems={['center', 'right']}
              moveItem={moveItem}
              label="Left Box"
              data={data}
            />
            <Box
              title=""
              items={centerItems}
              setItems={setCenterItems}
              canDropItems={[]}
              moveItem={moveItem}
              label="Center Box"
              data={data}
            />
            <Box
              description="Drop Interested Strategies Here"
              title="Interested Strategies"
              items={rightItems}
              setItems={setRightItems}
              canDropItems={['center', 'left']}
              moveItem={moveItem}
              label="Right Box"
              data={data}
            />
          </div>
        </DndProvider>
      </div>

      <Modal
        title="Create Scenario"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={createStrategyHistory}>
            Create Scenario
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="User">
                      <Select
                        showSearch
                        placeholder="Select a user"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        name="user"
                        value={user}
                        onChange={(value)=> setUser(value)}
                      >
                        {user_list.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
        </Form>
      </Modal>
    </div>
  )

  return Main
}

export default StrategySelection

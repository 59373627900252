export const editorKey = 'c96gicrnm2drzk07f227vtt5wwq67u9qrjt5d9c3oibxclz2'
export const LIFE_INSURANCE = '5dec49a6-0501-4584-ad9a-03a2fe4c735b' // form id
export const GENERAL_INSURANCE = 'e7eac8c5-7170-4f16-8fa9-1ab365f0d363' // form id
export const HEALTH_INSURANCE = '48b6d24d-20f7-4dee-84a1-e7bfa2f43979' // form id
export const NET_INSURANCE_PAGE_ID = '65f35940-8533-46e7-a6b0-379a315d81f0'
export const NET_CASHFLOW_PAGE_ID = 'ddf62d34-dede-4ad1-b543-7d9adced93ff'
export const INCOME_FORM_ID = 'fe2cd31c-f7f8-4fdc-8be4-8328ab0e03d9' // form id
export const EXPENSE_FORM_ID = '55214bee-df2d-4561-aaea-f794881c57b6' // form id

export const SUM_INSURED_FIELDS = [
  '10831d18-46aa-4dda-bcb6-99ed7d5e804f',
  '0df5e646-3b8d-49e9-bb50-302a4a51639d',
  '3f517515-b926-4fb2-8d9a-3a6a1f041c0a',
  'dcd998b3-aed3-4dae-9908-cce2c87ed12c',
  '9be6e724-5dea-45c5-a2de-9f48a3192dde',
  'dc410b60-f194-4414-a816-5d3bf8866300',
  '86d9f88c-4073-479f-918a-d2691a891cb5',
  '74e01b5f-4849-42fd-a19f-d8b8d119a77f',
  'dae9144e-cd30-439e-84d6-15cd210e1b53',
  '5be9678a-2482-477e-bda8-b3d2f698a97f',
  '748b3302-9767-4a61-b348-16ec9c268630',
  'a42ff18f-9c38-48dc-827e-bd05f003e88e',
  '435fd128-9d57-49b5-9a97-49c416d416be',
  '646ac0e9-8422-47bf-9367-c124ac88a542',
  '6724e57f-4b97-4db7-9a20-12a24ae0e000',
  '0b6b76e5-2313-4676-81b4-bf180314b872',
  '444d0036-b45d-4df3-930b-1253b3e569f1',
  '5a8cc7ae-fb36-4fb3-b461-59d8da17180b',
]

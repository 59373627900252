import React, {
  Component,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Nestable from 'react-nestable'
import {
  Select,
  Tabs,
  InputNumber,
  Radio,
  Space,
  Table,
  Input,
  Modal,
  Cascader,
  Button,
} from 'antd'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons'
import {
  AdminLayout,
  Boolean,
  Date,
  MultiLineText,
  MultipleSelect,
  Number,
  SingleLineText,
  ElementField,
  CheckList,
  WorkbookSetting,
} from 'Components'
import '../form.scss'
import Attachment from 'Components/Fields/attachment'
import RichText from 'Components/Fields/richText'
import SingleSelect from 'Components/Fields/singleSelect'
import Heading from 'Components/Fields/heading'
import { get } from 'lodash'
import { AuthService, FormService, PageService, PageServiceService } from 'services'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import produce from 'immer'
import Gap from 'Components/Fields/gap'
import ColorSelector from 'Components/Fields/color'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ReactSortable } from 'react-sortablejs'
import { setRoutes } from 'Store/reducers/routeSlice'
import ProviderField from 'Components/Fields/providerField'
import { getAdviseForOptions } from 'services/advise_for'
const { TabPane } = Tabs
const type = 'DraggableBodyRow'
const workbookGroupId = 'acdd415f-8543-4885-bb7c-485e7547a5a4'
const workbookGroupFieldId = '2e1fa45c-27b1-40b7-99e5-01f952eaa58e'
const progressGroupFieldId = 'ffeede29-6f12-47d1-b016-3915e9658432'
const progressGroupId = '25627427-7290-448e-b0d1-5db5d8ef8aad'
const progressFormFieldId = '08d641da-2b26-4861-9472-39603bb06279'

//----
const workbookFormId = 'c39bb863-e943-48ae-afaa-5279d1713dbb'

const PreviewModal = forwardRef(
  (
    {
      visible,
      handleOk,
      handleCancel,
      items,
      name,
      noModal,
      displayName,
      showSave,
      preview,
      pageId,
      wizard_history,
      wizard_is_locked,
      hasSidebarCondition,
    },
    ref
  ) => {
    const option_data = useSelector((state) => state.entityReducer)
    const [data, setData] = useState({})
    const [groupData, setGroupData] = useState({})
    const [groupFields, setGroupFields] = useState({})
    const [attachments, setAttachments] = useState([])
    const [workbookStatuModal, setWorkbookStatusModal] = useState(false)
    const [workbookProgressModal, setWorkbookProgressModal] = useState(false)
    const [deleteAttachments, setDeleteAttachments] = useState([])
    const [formRecord, setFormRecord] = useState('')
    const [form, setForm] = useState(null)
    const timer = useRef(null)
    const [formVisible, setFormVisible] = useState(false)
    const [formBody, setFormBody] = useState({
      field_data: {},
      group_data: {},
    })
    const [customFormData, setCustomFormData] = useState({})
    const [dataLoading, setDataLoading] = useState(true)
    const [formFields, setFormFields] = useState({})
    const [progressRecordId, setProgressRecordId] = useState('')
    const [formOptions, setFormOptions] = useState({})
    const [groupOptions, setGroupOptions] = useState({})
    const [workbookStatus, setWorkbookStatus] = useState('')
    const [statusRecordId, setStatusRecordId] = useState('')
    const [workbookProgress, setWorkbookProgress] = useState('')
    const [workbookProgressStatus, setWorkbookProgressStatus] = useState([])
    const [progressGroup, setProgressGroup] = useState('')
    const [formRecords, setFormRecords] = useState([])
    const [clients, setClients] = useState([])
    const [workbookLoading, setWorkbookLoading] = useState(false)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [conditions, setConditions] = useState({})
    const [pageConditions, setPageConditions] = useState({})
    const [formConditons, setFormConditons] = useState({})
    const [adviceForOptions, setAdviceForOptions] = useState({})

    const previewUser = useSelector((state) => state.previewUser)
    const user = useSelector((state) => state.user)
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const entity = params.get('entity')
      ? params.get('entity')
      : preview && previewUser?.details
        ? previewUser?.details?.id
        : user?.details?.id

    const getClients = () => {
      AuthService.users({ user_type: 'client' }).then((res) =>
        setClients(get(res, 'data.data', []))
      )
    }
    const dispatch = useDispatch()
    const _renderFields = (_formData, groupId, groupIndex, form, itemId) => {
      if (!_formData) return null
      let formData = JSON.parse(JSON.stringify(_formData))
      if (groupId) {
        formData.isGroup = true
      }
      if (!groupIndex && groupId && !conditions?.[groupId]?.[_formData?.id]) return null
      if (form?.form?.id && !formConditons?.[form.form.id]?.[itemId]) return null
      if (!groupId && !form?.form?.id && !pageConditions[itemId]) return null

      switch (formData.type) {
        case 'checklist':
          return (
            <CheckList
              {...formData}
              user={previewUser || user}
              clients={clients}
              value={getDataValue(formData, groupId, groupIndex, form)?.map((item) => {
                let obj = { ...item }
                delete obj.chosen
                return obj
              })}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
            />
          )
        case 'color':
          return (
            <ColorSelector
              {...formData}
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
            />
          )
        case 'text':
          return (
            <SingleLineText
              {...formData}
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
            />
          )
        case 'gap':
          // var rows=renderGaps(formData.rows)
          return <Gap {...formData} />
        case 'textarea':
          return (
            <MultiLineText
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              {...formData}
              dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
            />
          )
        case 'attachment': {
          return (
            <Attachment
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              setAttachments={(id) => {
                let arr = [...attachments]
                arr.push(id)
                setAttachments(arr)
              }}
              setDeleteAttachments={(url) => {
                let arr = [...deleteAttachments]
                arr.push(url)
                setDeleteAttachments(arr)
              }}
              {...formData}
            />
          )
        }
        case 'rich_text':
          return (
            <RichText
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
              {...formData}
            />
          )
        case 'multiple_select':
          return (
            <MultipleSelect
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
              {...formData}
            />
          )
        case 'single_select':
          return (() => {
            if (formData?.created_by) {
              return (
                <SingleLineText
                  {...formData}
                  value={
                    previewUser.details
                      ? `${previewUser?.details?.first_name} ${previewUser.details.last_name}`
                      : `${user.details.first_name} ${user.details.last_name}`
                  }
                />
              )
            }
            const PROVIDER_TYPES = [
              'product_provider',
              'asset_provider',
              'liability_provider',
              'general_insurance_provider',
              'life_insurance_provider',
              'health_insurance_provider',
            ]

            return PROVIDER_TYPES.includes(formData.options_type) ? (
              <ProviderField
                value={getDataValue(formData, groupId, groupIndex, form)}
                onChange={(val) => {
                  handleDataChange(val, formData, groupId, groupIndex, form)
                }}
                dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
                {...formData}
              />
            ) : (
              <SingleSelect
                value={getDataValue(formData, groupId, groupIndex, form)}
                onChange={(val) => {
                  handleDataChange(val, formData, groupId, groupIndex, form)
                }}
                dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
                {...formData}
              />
            )
          })()
        case 'heading':
          return <Heading {...formData} />
        case 'boolean':
          return (
            <Boolean
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
              {...formData}
            />
          )
        case 'date':
          return (
            <Date
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              {...formData}
              dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
            />
          )
        case 'number':
          return (
            <Number
              dependantValue={getDependantValue(formData, groupId, groupIndex, form)}
              value={getDataValue(formData, groupId, groupIndex, form)}
              onChange={(val) => {
                handleDataChange(val, formData, groupId, groupIndex, form)
              }}
              {...formData}
            />
          )
        default:
          break
      }
    }

    const getDependantValue = (formData, groupId, groupIndex, form) => {
      return formData.dependsOn
        ? formData.dependsOn.options.filter(
            (opt) => getDataValue(formData.dependsOn, groupId, groupIndex, form) == opt.value
          )?.[0]?.id
        : undefined
    }
    const getFormRecords = (form) => {
      PageService.getFormData({
        form,
        entity: preview ? previewUser.details.id : user.details.id,
        page: pageId,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
      })
        .then((res) => {
          if (res.data.data && res.data.data.length) {
            const nextState = produce(customFormData, (draft) => {
              draft[form] = res.data.data.map((item) => {
                let o = {
                  data: { ...item.field_data },
                  record_id: item.id,
                  body: {
                    field_data: item.field_data,
                    group_data: item.group_data,
                  },
                }
                Object.keys(item.group_data).map((key) => {
                  o = { ...o, data: { ...o.data, ...item.group_data[key] } }
                })
                return o
              })
            })
            setCustomFormData((prev) => ({ ...prev, ...nextState }))
          } else {
            prepareFormData(items, true)
          }
        })
        .catch((err) => console.log(err))
    }
    const getDataValue = (formData, groupId, groupIndex, _form) => {
      if (form && groupId) {
        return formBody.group_data?.[groupId]?.[formData.id]
      } else if (groupId) {
        return groupData[groupId][groupIndex].data[formData.id]
      } else if (_form) {
        return formBody.field_data?.[formData.id]
      } else {
        return data[formData.id]
      }
    }
    let debounceTimer = useRef(null)
    // let debounceTimer1 = useRef(null);

    const debounce = (cb) => {
      clearTimeout(debounceTimer.current)
      debounceTimer.current = setTimeout(() => {
        cb()
      }, 1200)
    }
    // const debounce1 = (cb) => {
    //   clearTimeout(debounceTimer1.current);
    //   debounceTimer1.current = setTimeout(() => {
    //     cb();
    //   }, 1200);
    // };
    const handleDataChange = (val, formData, groupId, groupIndex, _form) => {
      if (groupId && form) {
        let _formBody = JSON.parse(JSON.stringify(formBody))
        let _group_data = { ..._formBody.group_data }
        if (!_group_data[groupId]) {
          _group_data[groupId] = {}
        }
        _group_data[groupId][formData.id] = val
        _formBody.group_data = _group_data
        if (val && formData.has_dependent_condition) {
          debounce(() => fetchSingleGroupCondition(groupId, _formBody?.group_data?.[groupId] || {}))
        }
        setFormBody(_formBody)
      } else if (groupId) {
        const nextState = produce(groupData, (draft) => {
          draft[groupId][groupIndex].data[formData.id] = val
        })
        setGroupData(nextState)
      } else if (_form) {
        let _formBody = JSON.parse(JSON.stringify(formBody))
        let _field_data = { ..._formBody.field_data }
        _field_data[formData.id] = val
        _formBody.field_data = _field_data
        if (val && formData.has_dependent_condition) {
          debounce(() => fetchSingleFormCondition(_form.form.id, _formBody.field_data || {}))
        }
        setFormBody(_formBody)
      } else {
        if (formData.has_dependent_condition) {
          let pageData = JSON.parse(JSON.stringify(data))
          pageData[formData.id] = val
          debounce(() => fetchPageCondition(pageId, pageData || {}))
        }
        // if (formData.has_sidebar_condition) {
        //   debounce1(() => {
        //     AuthService.detailEntity(entity, { detail: true }).then((res) => {
        //       dispatch(setRoutes(res.data.data.current_page_config));
        //     });
        //   });
        // }
        setData((prev) => {
          let _data = JSON.parse(JSON.stringify(prev))
          _data[formData.id] = val
          return _data
        })
      }
    }
    const renderElementsOfForm = (items, form) => {
      return items?.map((item) => {
        if (item.type == 'field' && item.field) {
          return _renderFields(
            {
              ...item.field,
              has_dependent_condition: item?.has_dependent_condition,
              // has_sidebar_condition: item?.has_sidebar_condition,
            },
            null,
            null,
            form,
            item.id
          )
        }
        if (item.type == 'group') {
          return form ? renderNestedGroup(item) : renderGroup(item)
        }
        if (item.type == 'tab_group') {
          if (form?.form?.id && formConditons[form.form.id]?.[item.id]) {
            return renderTabs(item, form)
          }
          if (pageConditions[item.id]) return renderTabs(item, form)
        }
        if (item.type == 'form') {
          if (pageConditions[item.id]) return renderForm(item.form.items, item)
        }
        if (item.type == 'element') {
          if (pageConditions[item.id]) return renderElement(item)
        }
      })
    }
    const handleWorkbookSettingDrag = (val) => {
      let newData = JSON.parse(JSON.stringify(val))
      newData.data[progressGroupFieldId] = newData.data[progressGroupFieldId].map((item) => item.id)
      console.log('final data', newData)
      _editProgressDrag(newData.data, val.record_id)
    }
    const handleWorkbookDrag = (val) => {
      let newData = JSON.parse(JSON.stringify(val))
      newData.data[progressFormFieldId] = newData.data[progressFormFieldId].map((item) => item.id)
      console.log('final data', newData)
      _editProgressDrag(newData.data, val.record_id)
    }
    const renderElement = (item) => {
      return workbookLoading ? null : (
        <ElementField
          getFormRecords={getFormRecords}
          handleWorkbookCardClick={handleWorkbookCardClick}
          formRecords={customFormData[workbookFormId]}
          getFormDetails={getFormDetails}
          deleteRecord={(id, groupId) => {
            PageService.deleteGroupRecord(id).then((res) => getGroupData(groupId))
          }}
          addWorkbookSettingStatus={(id, val) => {
            addGroupRecord(workbookGroupId, true)
            setWorkbookStatusModal(true)
            setWorkbookStatus(val || '')
            setStatusRecordId(id || '')
          }}
          addWorkbookSettingProgress={(id, val, progresses) => {
            getProgressGroup()
            addGroupRecord(progressGroupId, true)
            setWorkbookProgressModal(true)
            setWorkbookProgress(val || '')
            setProgressRecordId(id || '')
            setWorkbookProgressStatus(progresses?.map((item) => item.id) || [])
          }}
          progress={groupData[progressGroupId]}
          statuses={groupData[workbookGroupId]}
          element={item}
          handleDragChange={handleWorkbookSettingDrag}
          handleWorkbookDrag={handleWorkbookDrag}
          preview={preview}
          wizard_history={wizard_history}
          wizard_is_locked={wizard_history}
        />
      )
    }
    const renderNestedGroup = (group) => {
      return group.group.fields.map((field) =>
        _renderFields(
          {
            ...field.field,
            has_dependent_condition: field?.has_dependent_condition,
          },
          group.group.id
        )
      )
    }
    const booleanResolver = (val) => {
      switch (val) {
        case 'no':
          return 'No'
        case 'yes':
          return 'Yes'
        case 'true':
          return 'True'
        case 'false':
          return 'False'
        default:
          return ''
      }
    }
    const getTableValue = (fieldId, value) => {
      if (!value) return ''
      let field = formFields[fieldId]?.field
      let type = field?.type
      if (type === 'checklist') {
        if (value && value.length) {
          let arr = []
          for (let i = 0; i < value.length; i++) {
            let str = ''
            let item = value[i]
            str = `Description: ${item?.description || '---'} | Assigned to: ${
              clients?.filter((it) => it.id === item?.assigned_to)?.[0]?.name || '---'
            }`
            arr.push(<p>{str}</p>)
          }
          return arr
        }
      }
      if (type === 'boolean') {
        return booleanResolver(value)
      }
      if (type == 'single_select') {
        if (
          field.options_type === 'product_provider' ||
          field.options_type === 'asset_provider' ||
          field.options_type === 'liability_provider'
        ) {
          return `${value?.providerName}- ${value?.productName}` || value?.provider
        }
        if (field.options_type === 'advise_for') {
          return adviceForOptions[value]
        }
        if (field.options_type === 'business') {
          return option_data.business.filter((item) => item.id == value)?.[0]?.business_name
        }
        if (field.options_type === 'user') {
          return option_data.user.filter((item) => item.id == value)?.[0]?.name
        }
        if (field.options_type === 'client') {
          return option_data.client.filter((item) => item.id == value)?.[0]?.name
        }
        return field.options.filter((item) => item.value == value)?.[0]?.name
      }
      if (type == 'text') {
        if (field.dynamicFields && Array.isArray(value)) {
          return value.map((val) => <div>{val}</div>)
        }
        return value
      }
      if (type == 'textarea') {
        if (field.dynamicFields && Array.isArray(value)) {
          return value.map((val) => <div>{val}</div>)
        }
        return value
      }
      if (type == 'number') {
        let prefix = field.numberFormat === 'currency' ? field.currency : ''

        if (field.dynamicFields && Array.isArray(value)) {
          if (field.numberType == 'single') {
            return value.map((val) => <div>{prefix + val.single}</div>)
          }
          if (field.numberType == 'range') {
            return value.map((val) => <div>{val.start + ' - ' + val.end}</div>)
          }
          if (field.numberType == 'flat_var') {
            return value.map((val) => <div>{val.flat + ' - ' + val.variable}</div>)
          }
          if (field.numberType == 'flat_var_range') {
            return value.map((val) => (
              <div>{val.start + ' - ' + val.end + '  ' + val.flat + ' + ' + val.variable}</div>
            ))
          }
        }
        if (field.numberType == 'single') {
          return prefix + value.single
        }
        if (field.numberType == 'range') {
          return value.start + ' - ' + value.end
        }
        if (field.numberType == 'flat_var') {
          return value.flat + ' - ' + value.variable
        }
        if (field.numberType == 'flat_var_range') {
          return value.start + ' - ' + value.end + '  ' + value.flat + ' + ' + value.variable
        }
        return JSON.stringify(value)
      }
      if (type == 'date') {
        if (field.dateVariant == 'dateRange' && Array.isArray(value)) {
          return (
            moment(value[0]).format('DD-MM-YYYY') + ' - ' + moment(value[1]).format('DD-MM-YYYY')
          )
        } else {
          return moment(value).format('DD-MM-YYYY')
        }
      }
      if (type == 'rich_text') {
        return <p dangerouslySetInnerHTML={{ __html: value }}></p>
      }
      if (type == 'multiple_select' && Array.isArray(value)) {
        let arr = value.map((val) => field.options.filter((item) => item.value == val)?.[0]?.name)

        return arr.join(', ')
      }
      if (
        type === 'multiple_select' &&
        typeof value === 'object' &&
        value !== null &&
        Object.keys(value)?.length
      ) {
        const optionsType = field.options_type
        return Object.keys(value).map((item) => {
          let options =
            field?.options_type === 'form'
              ? formOptions?.[field?.id]
              : field?.options_type === 'group'
                ? groupOptions?.[field?.id]
                : field?.options || []

          return (
            <p>{`${
              options?.filter((opt) => opt.value == item)?.[0]?.name || optionsType === 'advise_for'
                ? adviceForOptions[item]
                : item
            } - ${value[item]}${field?.display == 'percentage' ? '%' : ''}`}</p>
          )
        })
      }
      if (type == 'attachment') {
        if (field.multiAttachments && Array.isArray(value)) {
          return value.map((item) => (
            <a href={item} target="_blank">
              {item.split('/')[item.split('/').length - 1]}
            </a>
          ))
        }
        return (
          <a href={value} target="_blank">
            {value.split('/')[value.split('/').length - 1]}
          </a>
        )
      } else {
        return JSON.stringify(value)
      }
    }
    const getGroups = (items, groups = []) => {
      for (let i = 0; i < items.length; i++) {
        let el = items[i]
        if (el.type == 'group') {
          groups.push(el)
        } else if (el.type == 'tab_group') {
          getGroups(el.tabs, groups)
        } else if (el.children) {
          getGroups(el.children, groups)
        }
      }
      return groups
    }
    const getProgressGroup = () => {
      PageServiceService.GroupDetail(progressGroupId).then((res) => {
        setProgressGroup(res?.data?.data)
      })
    }

    const getFields = (items, fields = []) => {
      for (let i = 0; i < items.length; i++) {
        let el = items[i]
        if (el.type == 'field') {
          fields.push(el)
        }
        if (el.field) {
          fields.push(el)
        }
        if (el.type == 'form') {
          getFields(el.form.items, fields)
        }
        if (el.type == 'group') {
          getFields(el.group.fields, fields)
        } else if (el.type == 'tab_group') {
          getFields(el.tabs, fields)
        } else if (el.children) {
          getFields(el.children, fields)
        }
      }
      return fields
    }

    const handleWorkbookCardClick = (formRecord) => {
      getFormDetails()
      setFormBody(formRecord.body)
      setFormRecord(formRecord.record_id)
    }

    const getFormDetails = () => {
      FormService.getForm(workbookFormId).then((res) => {
        console.log('res', res)
        let form = {
          display_heading: false,
          element: null,
          field: null,
          form: res.data.data,
          group: null,
          reorder: null,
          type: 'form',
          view_only: false,
        }
        console.log('formmm', form)
        setForm(form)
        setFormVisible(true)
      })
    }
    const components = {
      body: {
        row: DraggableBodyRow,
      },
    }
    const moveRow = (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    }
    const renderForm = (items, form) => {
      let fields = []
      let groups = getGroups(items)
      groups.map((group) =>
        group.group.fields.map((field) => {
          if (field.include) {
            fields.push(field)
          }
        })
      )
      // items.map((item) => {
      //   if (item.type == "field") {
      //     fields.push(item);
      //   }
      // });
      let columns = fields.map((item) => ({
        title: item.field.displayName || item.field.name,
        key: item.id,
        dataIndex: item.field.id,
        width: item.percent ? `${item.percent}%` : `${100 / item?.length}%`,
        render: (val, data) => {
          return (
            <div className="table_responsive_cell">
              <h6>{item.field.displayName || item.field.name}</h6>
              {getTableValue(item.field.id, val)}
            </div>
          )
          // getTableValue(item.field.id, val);
        },
      }))
      const dataSource = customFormData[form.form.id]
        ?.filter((item) => item.record_id)
        .map((item) => ({
          ...item.data,
          ...item,
        }))

      if (columns.length && !form.view_only) {
        columns.push({
          title: 'Action',
          key: 'action',
          width: '250px',
          render: (text, record) => (
            <Space size="middle">
              <Button
                onClick={() => {
                  fetchGroupCondition(form, record)
                  setFormVisible(true)
                  setForm(form)
                  setFormBody(record.body)
                  setFormRecord(record.record_id)
                }}
                icon={<EditOutlined />}
                size={'medium'}
              />
              <Button
                danger
                icon={<DeleteOutlined />}
                size={'medium'}
                onClick={() => {
                  PageService.deleteFormData(record.record_id).then(() => {
                    getFormRecords(form.form.id)
                  })
                }}
              />
            </Space>
          ),
        })
      }
      return (
        <div>
          {form.display_heading && <h2>{form?.form?.display_name || form?.form?.name}</h2>}
          {columns.length ? (
            <DndProvider backend={HTML5Backend}>
              <Table
                className="custom_responsive_table"
                components={components}
                onRow={(_, index) => {
                  const attr = {
                    index,
                    moveRow: (dragIndex, hoverIndex) => {
                      const dragRow = dataSource[dragIndex]
                      let sorted = update(dataSource, {
                        $splice: [
                          [dragIndex, 1],
                          [hoverIndex, 0, dragRow],
                        ],
                      })
                      let obj = { ...customFormData }
                      obj[form.form.id] = sorted.map((item) => ({
                        body: item.body,
                        record_id: item.record_id,
                        data: item.data,
                      }))
                      // setCustomFormData(obj)
                      PageService.formReorder(sorted.map((item) => item.record_id))
                    },
                  }
                  return attr
                }}
                columns={columns}
                dataSource={dataSource}
              />
            </DndProvider>
          ) : null}
          {!form.view_only ? (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={{ width: '100%' }}
              size={'large'}
              // disabled={form.view_only}
              onClick={() => {
                setFormVisible(true)
                console.log('___form', form)
                setForm(form)
                fetchGroupCondition(form)
              }}
            >
              Add new record
            </Button>
          ) : null}
        </div>
      )
    }
    const fetchGroupCondition = (form, record) => {
      let data = {}
      fetchSingleFormCondition(form?.form?.id, record?.body?.field_data || {})
      let fields = flattenFields(form.form.items)
      fields.forEach((item) => {
        if (item.type == 'group') {
          let data = {}
          if (record) {
            data = record?.body?.group_data[item.group.id] || {}
          }
          // let body = {
          //   group: item.group.id,
          //   data: data,
          // };
          fetchSingleGroupCondition(item.group.id, data)
          // PageService.getGroupConditions(body).then((res) => {
          //   let obj = conditions;
          //   obj[item.group.id] = res.data.data;
          //   setConditions(obj);
          // });
        }
      })
    }

    const fetchPageCondition = (page, data = {}) => {
      let body = {
        page,
        data,
      }
      PageService.getPageCondition(body, entity).then((res) => {
        setPageConditions(res.data.data)
      })
    }

    const fetchSingleGroupCondition = (group, data) => {
      let body = {
        group,
        data,
      }
      PageService.getGroupConditions(body).then((res) => {
        setConditions((prev) => ({ ...prev, [group]: res.data.data }))
      })
    }
    const fetchSingleFormCondition = (form, data) => {
      let body = {
        form,
        data,
      }
      PageService.getFormConditions(body).then((res) => {
        setFormConditons((prev) => ({ ...prev, [form]: res.data.data }))
      })
    }

    const [status, setStatus] = useState([
      { id: 1, name: 'Analyse' },
      { id: 2, name: 'New' },
      { id: 3, name: 'Blocked' },
    ])
    const _saveGroupData = (groupId, groupIndex) => {
      let body = {
        group: workbookGroupId,
        data: { [workbookGroupFieldId]: workbookStatus },
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type == 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }
      if (statusRecordId) {
        PageService.updateGroupData(body, statusRecordId)
          .then((res) => {
            setWorkbookStatusModal(false)
            getGroupData(workbookGroupId)
          })
          .finally(() => {
            setStatusRecordId('')
            setWorkbookStatus('')
          })
      } else {
        PageService.saveGroupData(body)
          .then((res) => {
            setWorkbookStatusModal(false)
            getGroupData(workbookGroupId)
          })
          .finally(() => {
            setStatusRecordId('')
            setWorkbookStatus('')
          })
      }
    }
    const _editProgressDrag = (data, id) => {
      setWorkbookLoading(true)
      let body = {
        group: progressGroupId,
        data,
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type == 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }

      PageService.updateGroupData(body, id).then(() => {
        getGroupData(progressGroupId, () => setWorkbookLoading(false))
        getGroupData(workbookGroupId)
      })
    }
    const _editProgressGroupData = (statusId) => {
      setWorkbookLoading(true)
      let body = {
        group: progressGroupId,
        data: {
          [workbookGroupFieldId]: groupData[progressGroupId][0].data[workbookGroupFieldId],
          [progressGroupFieldId]: [
            ...(groupData[progressGroupId][0].data[progressGroupFieldId] || []),
            statusId,
          ],
        },
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type == 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }

      PageService.updateGroupData(body, groupData[progressGroupId][0].record_id).then(() => {
        getGroupData(progressGroupId, () => setWorkbookLoading(false))
      })
    }
    const _saveProgressGroupData = (groupId, groupIndex) => {
      let body = {
        group: progressGroupId,
        data: {
          [workbookGroupFieldId]: workbookProgress,
          [progressGroupFieldId]: workbookProgressStatus,
        },
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type == 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }
      setWorkbookLoading(true)
      if (progressRecordId) {
        PageService.updateGroupData(body, progressRecordId)
          .then(() => {
            setWorkbookProgressModal(false)
            getGroupData(progressGroupId, () => setWorkbookLoading(false))
          })
          .finally(() => {
            setProgressRecordId('')
            setWorkbookProgress('')
            setWorkbookProgressStatus([])
          })
      } else {
        PageService.saveGroupData(body)
          .then(() => {
            setWorkbookProgressModal(false)
            getGroupData(progressGroupId, () => setWorkbookLoading(false))
          })
          .finally(() => {
            setProgressRecordId('')
            setWorkbookProgress('')
            setWorkbookProgressStatus([])
          })
      }
    }
    const renderGroup = (item) => {
      if (!groupData || !Object.keys(groupData).length) return
      let group = groupData[item.group.id] || []
      let groupId = item.group.id

      return (
        <div>
          {item.display_heading && <h2>{item?.group?.display_name || item?.group?.name}</h2>}
          <ReactSortable
            list={group.map((item) => ({ ...item, chosen: true }))}
            onEnd={(e, val) => {
              if (!group.filter((item) => item.record_id).length) return
              clearTimeout(timer.current)
              timer.current = setTimeout(() => {
                PageService.groupReorder(
                  group.filter((item) => item.record_id).map((item) => item.record_id)
                )
              }, 1500)
            }}
            setList={(val) => {
              setGroupData((prev) => ({ ...prev, [groupId]: val }))
            }}
          >
            {group.map((itm, groupIndex) => (
              <div key={item.record_id} className="inline_group_fields">
                {[
                  Object.keys(itm.data).map((field, index) => (
                    <div
                      style={
                        groupFields?.[field]?.percent
                          ? { width: `${groupFields?.[field]?.percent}%` }
                          : null
                      }
                      key={index}
                      className="each_group_field"
                    >
                      {_renderFields(
                        {
                          ...groupFields?.[field]?.field,
                          isReadOnly: item.view_only,
                        },
                        groupId,
                        groupIndex
                      )}
                    </div>
                  )),
                ]}
                {!item.view_only && (
                  <div className="each_group_field action_record_buttons">
                    <div className="form_field">
                      <label>Action</label>
                      <div className="align_button_container">
                        <Button
                          size="large"
                          type="link"
                          onClick={() => {
                            let body = {
                              group: groupId,
                              data: groupData[groupId][groupIndex].data,
                              page: pageId,
                              attachments,
                              delete_attachments: deleteAttachments,
                            }
                            if (preview && previewUser) {
                              body.entity = { id: previewUser.details.id }
                              if (previewUser.details.user_type == 'business') {
                                body.entity.type = 'business'
                              }
                            } else {
                              body.entity = { id: user.details.id }
                            }
                            if (itm.record_id) {
                              PageService.updateGroupData(body, itm.record_id).then(() =>
                                getGroupData(groupId)
                              )
                            } else {
                              PageService.saveGroupData(body).then(() => getGroupData(groupId))
                            }
                          }}
                          icon={<SaveOutlined />}
                        ></Button>

                        {itm.record_id ? (
                          <Button
                            size="large"
                            danger
                            icon={<CloseSquareOutlined />}
                            type="link"
                            onClick={() => {
                              PageService.deleteGroupRecord(itm.record_id).then((res) =>
                                getGroupData(groupId)
                              )
                            }}
                          ></Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </ReactSortable>
          <div>
            {!item.view_only && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={{ width: '100%' }}
                disabled={item.view_only}
                onClick={() => addGroupRecord(item.group.id)}
              >
                Add new record
              </Button>
            )}
          </div>
        </div>
      )
    }
    const addGroupRecord = (groupId, isWorkbook) => {
      let temp = { ...groupData }
      let arr = [...temp[groupId]]
      arr.push({ ...arr[arr.length - 1], record_id: null })
      temp[groupId] = arr
      setGroupData(temp)
    }
    const renderTabs = (item, form) => {
      let tabs = item.tabs
      let conditions = {}
      if (form?.form?.id) {
        conditions = formConditons[form.form.id]
      } else {
        conditions = pageConditions
      }
      return (
        <Tabs defaultActiveKey="0" type="card" size={'small'}>
          {tabs
            .filter((item) => conditions?.[item.id])
            .map((tab, i) => (
              <TabPane tab={tab.name} key={i}>
                <p>{tab.description}</p>
                {renderElementsOfForm(tab.children, form)}
              </TabPane>
            ))}
        </Tabs>
      )
    }

    useEffect(() => {
      getAdviseForOptions(entity).then((res) => {
        const optionsObj = res?.data?.data || {}
        setAdviceForOptions(optionsObj)
      })
    }, [])
    useEffect(() => {
      getClients()
      if (preview && previewUser.details) {
        getPageData()
        getGroupData(progressGroupId)
      } else if (!preview && user.details) {
        getPageData()
      }
    }, [previewUser, pageId, entity])
    useEffect(() => {
      if (items) {
        setShowSaveButton(false)
        prepareGroupData(items)
        prepareFormData(items)
        handleShowSave(items)
        if (pageId) prepareFormGroupOptions(items)
      }
    }, [items])
    const prepareFormGroupOptions = (items) => {
      let flattened = flattenFields(items)
      let fields = getFields(flattened)
      let selectFields = fields.filter(
        (item) => item?.field?.type === 'single_select' || item?.field?.type === 'multiple_select'
      )
      let trackObj = {}
      selectFields.map((item) => {
        if (!trackObj[item.field.id]) {
          trackObj[item.field.id] = true
          if (item?.field?.options_type === 'form') {
            if (item?.field?.options_type === 'form') {
              PageService.getFormDropdown({
                entity,
                field: item?.field?.id,
              }).then((res) => {
                setFormOptions({ [item.field.id]: res?.data?.data || [] })
              })
            }
            if (item?.field?.options_type === 'group') {
              PageService.getGroupDropdown({
                entity,
                field: item?.field?.id,
              }).then((res) => {
                setGroupOptions({ [item.field.id]: res?.data?.data || [] })
              })
            }
          }
        }
      })
    }
    const prepareGroupData = (_items, getGroup) => {
      let obj = {}
      let _groupFields = {}
      let items = flattenFields(_items)
      items.forEach((item) => {
        if (item.type == 'group') {
          if (pageId && !getGroup) getGroupData(item.group.id)
          let fieldMap = { data: {} }
          item.group.fields.map((field) => {
            fieldMap.data[field.field.id] = ''
            _groupFields[field.field.id] = field
          })
          obj[item.group.id] = [fieldMap]
        }
      })
      setGroupData(obj)
      setGroupFields(_groupFields)
      return obj
    }
    const flattenFields = (items, arr = []) => {
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        if (item.type == 'tab_group') {
          flattenFields(item.tabs, arr)
        } else if (item.children) {
          flattenFields(item.children, arr)
        } else {
          arr.push(item)
        }
      }
      return arr
    }
    const prepareFormData = (_items, getForm) => {
      let obj = {}
      let _formFields = {}
      let items = flattenFields(_items)
      items.forEach((item) => {
        if (item.type == 'form') {
          if (pageId && !getForm) getFormRecords(item.form.id)
          let fieldMap = { data: {} }
          let fields = getFields(item.form.items)
          fields.map((field) => {
            if (field.type == 'field' || field.field) {
              fieldMap.data[field.field.id] = ''
              _formFields[field.field.id] = field
            }
            if (field.type == 'group') {
              field.group.fields.map((item) => {
                fieldMap.data[item.field.id] = ''
                _formFields[item.field.id] = item
              })
            }
          })
          obj[item.form.id] = [fieldMap]
        }
      })
      // setCustomFormData(obj)
      setFormFields(_formFields)
      // return obj;
    }
    const getGroupData = (group, cb) => {
      setWorkbookLoading(true)
      let entity = preview ? previewUser?.details?.id : user?.details?.id
      let entityDetails = preview ? previewUser?.details : user?.details
      if (group == progressGroupId && entityDetails.user_type == 'client') {
        entity = preview ? previewUser?.details?.business : user?.details?.business
      }
      PageService.getGroupData({
        group,
        entity,
        page: pageId,

        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
      })
        .then((res) => {
          if (res.data.data && res.data.data.length) {
            const nextState = produce(groupData, (draft) => {
              draft[group] = res.data.data.map((item) => ({
                data: item.data,
                record_id: item.id,
                is_selected: item.is_selected,
              }))
            })
            setGroupData((prev) => ({ ...prev, ...nextState }))
          } else {
            prepareGroupData(items, true)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          if (cb) cb()
          setWorkbookLoading(false)
        })
    }
    const getPageData = () => {
      if (pageId) {
        PageService.getPageData({
          page: pageId,
          entity: entity,
          wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
          wizard: wizard_history ? wizard_history : '',
        })
          .then((res) => {
            setData(get(res, 'data.data.data', {}))
            fetchPageCondition(pageId, get(res, 'data.data.data', {}))
          })
          .finally(() => {
            setDataLoading(false)
          })
      }
    }

    const savePageData = () => {
      let obj = {
        data,
        page: pageId,
        attachments,
        delete_attachments: deleteAttachments,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
        wizard: wizard_history ? wizard_history : '',
      }
      if (preview && previewUser) {
        obj.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type == 'business') {
          obj.entity.type = 'business'
        }
      } else {
        obj.entity = { id: user.details.id }
      }

      return PageService.updatePageData(obj, {
        page: pageId,
        entity: preview ? previewUser.details.id : user.details.id,
      })
    }
    //forward ref to call this method via questionnaire wizard.
    useImperativeHandle(ref, () => ({
      savePageData: savePageData,
    }))
    const saveFormData = () => {
      let obj = {
        form: form.form.id,
        delete_attachments: deleteAttachments,
        attachments,
        page: pageId,
        ...formBody,
        // group_data: {
        //   g1: {
        //     f1: "value",
        //     f2: "value",
        //   },
        //   g2: {
        //     f1: "value",
        //     f2: "value",
        //   },
        // },
      }
      if (preview && previewUser) {
        obj.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type == 'business') {
          obj.entity.type = 'business'
        }
      } else {
        obj.entity = { id: user.details.id }
      }
      if (formRecord) {
        PageService.updateFormData(obj, formRecord).then((res) => {
          closeFormModal()
          getFormRecords(form.form.id)
        })
      } else {
        PageService.saveFormData(obj).then((res) => {
          closeFormModal()
          getFormRecords(form.form.id)
          // addNewTaskToFirstProgress(res.data.data.id);
        })
      }
    }
    const addNewTaskToFirstProgress = (taskId) => {
      let body = {
        group: progressGroupId,
        data: {
          ...groupData[progressGroupId][0].data,
          [progressFormFieldId]: [
            ...(groupData[progressGroupId][0].data?.[progressFormFieldId] || []),
            taskId,
          ],
        },
        page: pageId,
      }
      if (preview && previewUser) {
        body.entity = { id: previewUser.details.id }
        if (previewUser.details.user_type == 'business') {
          body.entity.type = 'business'
        }
      } else {
        body.entity = { id: user.details.id }
      }
      PageService.updateGroupData(body, groupData[progressGroupId][0].record_id).then(() =>
        getGroupData(progressGroupId)
      )
    }
    const closeFormModal = () => {
      setFormVisible(false)
      setForm(null)
      setFormBody({})
      setFormRecord('')
    }
    const handleFormOk = () => {
      closeFormModal()
    }
    const handleFormCancel = () => {
      closeFormModal()
    }
    const handleShowSave = (items) => {
      for (let i = 0; i < items.length; i++) {
        let el = items[i]
        if (el.type == 'field') {
          setShowSaveButton(true)
        }
        if (el.type == 'tab_group') {
          for (let i = 0; i < el.tabs.length; i++) {
            let tab = el.tabs[i]
            handleShowSave(tab.children)
          }
        }
      }
    }
    if (noModal) {
      return (
        <div className="page_layout_dynamic">
          <h1 className="flex_title">
            <span className="title">{displayName || name}</span>
            {showSave && showSaveButton && !wizard_history ? (
              <Button
                size="large"
                type="primary"
                className="page_save_action"
                onClick={() => {
                  savePageData().then((res) => {
                    if (hasSidebarCondition) {
                      AuthService.detailEntity(entity, { detail: true }).then((res) => {
                        dispatch(setRoutes(res.data.data.current_page_config))
                      })
                    }
                  })
                }}
              >
                Save
              </Button>
            ) : null}
          </h1>
          {!dataLoading && renderElementsOfForm(items)}
          {formVisible && (
            <Modal
              visible={formVisible}
              title={'Add Record'}
              onOk={handleFormOk}
              onCancel={handleFormCancel}
              footer={null}
              width={1800}
              style={{ maxWidth: '90%' }}
            >
              {form.display_heading && <h2>{form?.form?.display_name || form?.form?.name}</h2>}
              {!dataLoading && renderElementsOfForm(form?.form?.items || [], form)}
              <Button
                onClick={saveFormData}
                type="primary"
                style={{ width: '100%' }}
                size={'large'}
              >
                Save
              </Button>
            </Modal>
          )}
          {workbookStatuModal && (
            <Modal
              visible={workbookStatuModal}
              title={'Add Status'}
              footer={null}
              width={1800}
              style={{ maxWidth: '90%' }}
              onCancel={() => setWorkbookStatusModal(false)}
              onOk={() => setWorkbookStatusModal(false)}
            >
              <SingleLineText
                name={'Name'}
                layout="horizontal"
                value={workbookStatus}
                onChange={(val) => setWorkbookStatus(val)}
              />
              <Button
                type="primary"
                style={{ width: '100%' }}
                size={'large'}
                onClick={_saveGroupData}
              >
                Save
              </Button>
            </Modal>
          )}
          {workbookProgressModal && (
            <Modal
              visible={workbookProgressModal}
              title={'Add Progress'}
              footer={null}
              width={1800}
              style={{ maxWidth: '90%' }}
              onCancel={() => setWorkbookProgressModal(false)}
              onOk={() => setWorkbookProgressModal(false)}
            >
              <SingleLineText
                name={'Name'}
                layout="horizontal"
                value={workbookProgress}
                onChange={(val) => setWorkbookProgress(val)}
              />
              {/* <MultipleSelect
              display="dropdown"
              name="Status"
              options_type="group"
              id={progressGroupFieldId}
              onChange={(val) => setWorkbookProgressStatus(val)}
              value={workbookProgressStatus}
            /> */}
              <Button
                type="primary"
                style={{ width: '100%' }}
                size={'large'}
                onClick={_saveProgressGroupData}
              >
                Save
              </Button>
            </Modal>
          )}
        </div>
      )
    }
    return (
      <Modal
        visible={visible}
        title={name + ' preview'}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1800}
        style={{ maxWidth: '90%' }}
      >
        <h2>{displayName || name}</h2>
        {!dataLoading && renderElementsOfForm(items)}
      </Modal>
    )
  }
)

export default PreviewModal

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}

      if (dragIndex === index) {
        return {}
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
        ...style,
      }}
      {...restProps}
    />
  )
}

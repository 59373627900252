import { SUM_INSURED_FIELDS } from '../../../constants'
import { isEqual, sum } from 'lodash'

export const getStrategyColumValue = (record) => {
  const data = record?.data
  const proposedData = record?.proposedData

  if (isNewRecord(data, proposedData)) {
    return 'New'
  }

  if (isNoChanges(data, proposedData)) {
    return 'Maintain'
  }

  if (isSumInsuredChanged(data, proposedData)) {
    return 'Vary'
  }

  if (!isNoChanges(data, proposedData) && !isSumInsuredChanged(data, proposedData)) {
    return 'Review'
  }

  if(!isSumInsuredChanged(data, proposedData)){
    return 'Close'
  }

  return ''
}

const isNewRecord = (data, proposedData) => {
  const isDataExist = data && Object.keys(data)?.length
  const isProposedDataExist = proposedData && Object.keys(proposedData)?.length
  if (!isDataExist && isProposedDataExist) {
    return true
  } else {
    return false
  }
}

const isNoChanges = (data = {}, proposedData = {}) => {
  return isEqual(data, proposedData)
}

const isSumInsuredChanged = (data, proposedData) => {
  for (let i = 0; i < SUM_INSURED_FIELDS.length; i++) {
    const sumField = SUM_INSURED_FIELDS[i]
    if (!isEqual(data?.[sumField], proposedData?.[sumField])) {
      return true
    }
  }
  return false
}

